.App {
  text-align: center;
}

.App-header {
  background-image: url('https://cdn.vox-cdn.com/thumbor/JgNyGimutNutR9o8kq9pr_WxAgQ=/0x0:2000x1335/1200x675/filters:focal(840x508:1160x828)/cdn.vox-cdn.com/uploads/chorus_image/image/61623387/2018_09_28_GoldLineBar_003.0.jpg');
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  padding-top: 0;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;

}

.drink {
  width: 70%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 2%;
}

.drink img {
    height: 300px;
    width: 300px;
    object-fit: scale-down;
}

p {
  font-size: 1rem;
  margin-top: 2%;
}

h4 {
  font-size: 1.2rem;
  margin: 1%;
}

h2 {
  font-size: 1.4rem;
  margin: 1%;
}

button {
  background-color: black;
  color: white;
  width: 150px;
  padding: 1%;
  border-radius: 20px;
  font-size: .8rem;
  margin-bottom: 3%;
}
.drinklist {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  flex-direction: column;
  width: 30%;
  color: black;
  margin-top: 2%;
  opacity: .8;
  border-radius: 20px;
}

.how {
  text-decoration: underline;
}

button:hover {
  background-color: white;
  color: black;
}